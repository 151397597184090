.warpper {
  padding: 0;
}

.header {
  padding: 25px 10px 10px 25px;
}

.body {
  padding: 12px 10px 20px 18px;
  font-size: 14px;
  table {
    border-spacing: 0;
    border-collapse: collapse;
  }
  thead th, tbody td {
    padding: 3px 10px;
    line-height: 21px;
  }
  thead th {
    text-align: left;
  }
  tbody tr {
    background-color: transparent;
    transition: all .3s;
  }
  tbody tr:hover {
    background-color: #f1f1f1;
  }
}

.update {
  color: #333;
  font-size: 12px;
  padding: 0 10px 40px 30px;
}

.notFound {
  color: #797979;
  a {
    color: #797979;
  }
}

.status {
  text-align: center;
}
.timeout .status span, .success .status span {
  padding: 0 2px;
  border-radius: 3px;
  font-size: 12px;
}

.timeout .status span {
  background-color: #F95C2B;
  color: #fff;
}
.success .status span {
  background-color: #28a745;
  color: #fff;
}